import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./Newsletter.css";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [note, setNote] = useState({
    note: "",
    status: "",
  });

  const recaptchaRef = React.createRef();

  const subscribe = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    setNote({
      note: "",
      status: "",
    });

    const regex = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,7})+$/);
    if (!regex.test(email)) {
      setNote({
        note: "Email error. Please enter a valid email and try again.",
        status: "error",
      });
      return setSubmitting(false);
    }

    const token = await recaptchaRef.current.executeAsync();
    if (!token) {
      setNote({
        note: "Something went wrong. Please try again.",
        status: "error",
      });
      return setSubmitting(false);
    }

    try {
      const data = JSON.stringify({ email, recaptcha_token: token });
      await fetch(
        "https://mailproxy.3air.io/api/sendgrid/v1/subscribe?tags=blog",
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-type": "application/json",
          },
          body: data,
        }
      )
        .then(() => {
          setNote({
            note: "Thank you for subscribing.",
            status: "success",
          });
          return setSubmitting(false);
        })
        .catch(() => {
          setNote({
            note: "Something went wrong. Please try again.",
            status: "error",
          });
          return setSubmitting(false);
        });
    } catch (error) {
      setNote({
        note: "Something went wrong. Please try again.",
        status: "error",
      });
      return setSubmitting(false);
    }
  };
  return (
    <>
      <h3>Subscribe to our mailing list to stay up to date</h3>
      <form id="newsletter" onSubmit={subscribe}>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey="6LcizmgfAAAAAAVBwAhBOTdchzmVJVkKR58dKp70"
        />
        <input
          type="email"
          value={email}
          placeholder="insert email"
          disabled={submitting}
          onChange={(e) => {
            setEmail(e.target.value);
            note.note && setNote({ note: "", status: "" });
          }}
        />
        <input type="submit" value="subscribe" disabled={submitting} />
        <div className={note.note ? note.status : ""}>{note.note}</div>
      </form>
    </>
  );
};
export default Newsletter;
