import React from "react";
import { Card as CardComponent } from "theme-ui";
import { Layout, Stack, Main } from "@layout";
import CardList from "@components/CardList";
import Card from "@components/Card";
import Divider from "@components/Divider";
import Seo from "@widgets/Seo";
import AuthorExpanded from "@widgets/AuthorExpanded";
import TableOfContentsExpanded from "@widgets/TableOfContentsExpanded";
import {
  PostBody,
  PostComments,
  PostCommentsFacebook,
  PostCommentsGraph,
  PostTagsShare,
} from "@widgets/Post";
import Newsletter from "../../../components/Newsletter/Newsletter";

const Post = ({
  data: { post, tagCategoryPosts, tagPosts, categoryPosts },
  ...props
}) => {
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : []),
  ];
  const { pageContext: { services = {}, siteUrl } = {} } = props;

  return (
    <Layout {...props}>
      <article>
        <Seo {...post} siteUrl={siteUrl} />
        <Divider />
        <Stack effectProps={{ effect: "fadeInDown" }}>
          <Main>
            <Card {...post} variant="horizontal-hero" omitExcerpt />
          </Main>
        </Stack>
        <Divider space={3} />
        <Stack effectProps={{ fraction: 0 }}>
          <Main>
            {post.tableOfContents?.items && (
              <>
                <TableOfContentsExpanded {...post} />
                <Divider />
              </>
            )}
            <CardComponent className="post_body" variant="paper-lg">
              <PostBody {...post} />
              <PostTagsShare {...post} location={props.location} />
              {services.disqus && <PostComments {...post} />}
              {services.graphComment && <PostCommentsGraph {...post} />}
              {services.facebookComment && (
                <PostCommentsFacebook {...post} siteUrl={siteUrl} />
              )}
              <Divider />
              <Newsletter />
            </CardComponent>
            <Divider />
            <AuthorExpanded author={post.author} />
          </Main>
        </Stack>
      </article>
      <Stack>
        <Main>
          <Divider />
          {post.category && (
            <CardList
              nodes={relatedPosts}
              variant={["vertical"]}
              columns={[1, 2, 3, 3]}
              limit={6}
              title="Related Posts"
              distinct
            />
          )}
        </Main>
      </Stack>
    </Layout>
  );
};

export default Post;
